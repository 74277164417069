import React, { Component } from 'react';
import profile from '../profile.jpg';
export default class About extends React.Component{
  render() {
    return (

      <section id="about">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-6 col-10 offset-1 offset-md-0">
              <h3 className="quote text-center">
                Live. Learn. <span className="colored">Loop</span>
              </h3>
              <p className="text-center">
                I am an Avid Reader, Lifelong Learner and capable of Great Abstraction.
              </p>
            </div>
            <div className="col-md-6 col-10 offset-1 offset-md-0">
              <img src={ profile } className="profile img-fluid" />
            </div>
          </div>
        </div>
      </section>
    );
  }
};

