import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App from './App';
import Project from './Project';


const Router = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path="/" component={ App } />
			<Route path="/projects/:projectId" component={ Project } />
		</Switch>
	</BrowserRouter>
);

export default Router;