import React, { Component } from 'react';
import ProjectSummary from './ProjectSummary';
export default class Portfolio extends React.Component{
  state = {
    projects: {
      project1: {
        name: "Bolt Plate Face Share Campaign",
        image: "/images/projects/plate-face-share.png",
        desc: "A website for gospel musician Victor Atenaga.",
        slug: "plate-face-share",
        url: "https://platefaceshare.com/"
      },
      project2: {
        name: "Laurie Idahosa",
        image: "/images/projects/laurie-idahosa.png",
        desc: "Creation of a platform for her online presence.",
        slug: "laurie-idahosa",
        url: "https://laurieidahosa.com"
      },
      project3: {
        name: "Omoluabi Mortgage Bank, Oshogbo",
        image: "/images/projects/ombplc.png",
        desc: "Created a website to show the bank products, services and offerings.Created a platform to handle account openings, loan pre-assessments and mortgage applications for potential customers.",
        slug: "ombplc",
        url: "https://ombplc.com"
      },
      project4: {
        name: "Yummy Puree",
        image: "/images/projects/yummy.png",
        desc: "Created an e-commerce solution to allow connecting with parents who need healthy, organic & fresh baby food.",
        slug: "yummy-puree",
        url: "https://yummy.eatgreencompany.com"
      },
      project5: {
        name: "Amoré Radio",
        image: "/images/projects/amr.png",
        desc: "Integrated the text and audio content of the company to create a single platform using Angular and JSON APIs where it can all be accessed.",
        slug: "amr-radio",
        url: "https://amore.ng/radio"
      },
      project6: {
        name: "Global Horn Industries Ltd",
        image: "/images/projects/globalhorn.png",
        desc: "Developed a System to handle management of products and registered distributors as well as tracking product demand by distributors.",
        slug: "global-horn",
        url: "http://globalhorn.com"
      },
      project7: {
        name: "Bariyu Yesufu",
        image: "/images/projects/bariyu.png",
        desc: "A website to showcase Bariyu's comedy as well as enable him sell his videos.",
        slug: "bariyu",
        url: "https://bariyu.com"
      },
      project8: {
        name: "13 Moons Africa",
        image: "/images/projects/13moons.png",
        desc: "Created a website for the graphic novel 13 Moons to allow downloads of the issues.",
        slug: "13moons",
        url: "https://13moons.africa"
      },
      project9: {
        name: "FanBox.Space",
        image: "/images/projects/fanbox.png",
        desc: "Created a website for the graphic novel 13 Moons to allow downloads of the issues.",
        slug: "fanbox",
        url: "https://fanbox.space"
      },
      project10: {
        name: "Dystopia The Game",
        image: "/images/projects/dystopia.png",
        desc: "Created a website for the graphic novel 13 Moons to allow downloads of the issues.",
        slug: "dystopia",
        url: "https://dystopiagame.com"
      }
    }
  };
  render() {
    return (

      <section id="portfolio">
        <div className="container-fluid">
          <div className="row">
            <div className="col-10 offset-1 offset-md-0 col-md-12 text-center">
              <h2>Projects &amp; Freelancing</h2>
            </div>
            {Object.keys(this.state.projects).map(key => <ProjectSummary key={key} details={this.state.projects[key]} /> )}
            <div className="col-10 offset-1 offset-md-0 col-md-12 text-center">
              {/* <a href="#" className="btn btn-primary">All Projects</a> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
};

