import React, { Component } from 'react';
export default class Header extends React.Component{
  render() {
    return (

      <header className="d-flex">
        <div className="container-fluid justify-content-center align-self-center">
          <div className="row">
            <div className="col-12">
              <h2>Hello, I'm</h2>
              <h1><span className="colored">Etin Obaseki</span>.</h1>
              <h3>Software <strike>Developer</strike> Engineer</h3>
              <a href="mailto:etinosaobaseki@gmail.com" className="btn btn-primary">Hire Me</a>
            </div>
          </div>
        </div>
      </header>
    );
  }
};
