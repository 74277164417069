import React, { Component } from 'react';
export default class ProjectSummary extends React.Component{
  render() {
  	return (
  		<div>
	  		<h3>
				{this.props.details.title}
			</h3>
			<p>
				{this.props.details.exercept}
			</p>
			<div className>
				<a href={this.props.details.link} target="_blank" className="btn btn-primary">Read Now</a>
			</div>
		</div>
	);
  }
};