import React, { Component } from 'react';
export default class Contact extends React.Component{
  render() {
    return (

      <section className="hero d-flex" id="contact">
        <div className="container-fluid justify-content-center align-self-center">
          <div className="row">
            <div className="col-10 offset-1 offset-md-0 col-md-12">
              <h1>Get In Contact</h1>
              <h4>For a New Project, To Hire Me or Just to Chat</h4>
            </div>
          </div>
          <div className="row text-center">
            <div className="col">
              <h3><i className="fas fa-phone" /></h3>
              <h4>Phone</h4>
              <p>
                <a href="tel:+2347061598693">+234 706 159 8693</a>
              </p>
            </div>
            <div className="col">
              <h3><i className="fas fa-envelope" /></h3>
              <h4>Email</h4>
              <p>
                <a href="mailto:etinosaobaseki@gmail.com">etinosaobaseki@gmail.com</a>
              </p>
            </div>
            <div className="col">
              <h3><i className="fas fa-globe" /></h3>
              <h4>Social</h4>
              <p>
                <a href="https://facebook.com/NotUrAverageDude" target="_blank">Facebook</a>
              </p>
              <p>
                <a href="https://twitter.com/obasekietinosa" target="_blank">Twitter</a>
              </p>
              <p>
                <a href="https://medium.com/@etinosaobaseki" target="_blank">Medium</a>
              </p>
              <p>
                <a href="https://www.instagram.com/e.t.i.n/" target="_blank">Instagram</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
};
