import React, { Component } from 'react';
// import logo from './logo.svg';
// import './App.css';
import Header from './header';
import About from './about';
import Portfolio from './portfolio';
import Casenotes from './casenotes';
import Contact from './contact';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <About />
        <Portfolio />
        <Casenotes />
        <Contact />
      </div>
    );
  }
}

export default App;
