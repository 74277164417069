import React, { Component } from 'react';
import Casenote from './Casenote';
export default class Casenotes extends React.Component{
  state = {
    caseNotes: {
      caseNote1: {
        title: "Hitting The Ground Running: My First Ever Crash",
        exercept: "Today, I became a man. No, I didn't lose my virginity or battle a wild tiger, it was something even grander than that. Today, my servers crashed. Multiple times. But, to tell this story properly, I must start at the beginning.",
        link: "https://medium.com/its-all-about-wordpress/hitting-the-ground-running-my-first-ever-crash-cc1905a594fc?source=search_post---------0",
        date: "Sep 3, 2017",
      },
      caseNote2: {
        title: "Sometimes, Just RESTART",
        exercept: "I learnt a really powerful life lesson from a weird Node.js malfunction. Here it is:",
        link: "https://medium.com/@etinosaobaseki/sometimes-just-restart-efe3eaa2a00c?source=search_post---------1",
        date: "Sep 7, 2017",
      },
      caseNote3: {
        title: "My Experience with writing HTML Emails",
        exercept: "I wrote my first set of HTML Emails over the weekend; The morale of this story is to never think you'll have it down in 'five minutes, tops'.",
        link: "https://medium.com/@etinosaobaseki/my-experience-with-writing-html-emails-ece3c85c4660?source=search_post---------0",
        date: "Sep 13, 2017",
      }
    }
  }
  render () {
    return (

      <section id="case-notes">
        <div className="container-fluid">
          <div className="row">
            <div className="col-10 offset-1 offset-md-0 col-md-12">
              <h2 className="text-center">Case Notes</h2>
              <ol>
                {Object.keys(this.state.caseNotes).map(key => <li className="note"><Casenote key={key} details={this.state.caseNotes[key]} /></li> )}
                <a href="https://notes.etin.space" className="btn btn-primary">All Notes</a>
              </ol>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

