import React, { Component } from 'react';
export default class ProjectSummary extends React.Component{
  render() {
    return (
      <div className=" col-md-6 col-10 offset-1 offset-md-0">
        <a href={this.props.details.url} target="_blank" >
        <div className="project">
        <img src={this.props.details.image} alt={this.props.details.name} className="img-fluid"/>
        <h4>
          {this.props.details.name}
        </h4>
        </div>
        </a>
      </div>
    );
  }
};
